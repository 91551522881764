import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { Transition } from "@headlessui/react";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { Calendar, Clock, Video } from "lucide-react";

import {
  CogIcon,
  GlobeAltIcon,
  ChartBarIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";

const Button = ({
  children,
  variant = "primary",
  className = "",
  ...props
}) => {
  const baseStyle = "px-4 py-2 rounded-md font-medium transition-colors";
  const variants = {
    primary: "bg-green-600 text-white hover:bg-green-700",
    secondary: "bg-blue-600 text-white hover:bg-blue-700",
    outline:
      "bg-transparent border border-green-600 text-green-600 hover:bg-green-50",
  };
  return (
    <button
      className={`${baseStyle} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default function Main() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({ success: true, text: "" });
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentVideo, setCurrentVideo] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const videos = [
    require("../assets/videos/sample-vid1.mp4"),
    require("../assets/videos/sample-vid2.mp4"),
    require("../assets/videos/sample-vid3.mp4"),
  ];

  const [videoAvailable, setVideoAvailable] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkVideoAvailability = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setVideoAvailable(false); // true when video is actually available
      setLoading(false);
    };

    checkVideoAvailability();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleVideoChange((currentVideo + 1) % videos.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [currentVideo, videos.length]);

  const handleVideoChange = (index) => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentVideo(index);
      setIsFading(false);
    }, 500);
  };

  const baseURL =
    process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${baseURL}/api/send-welcome-email`, {
        email,
        language: i18n.language || "en",
      });

      if (response.status === 200) {
        setMessage({ success: true, text: t("email_sent_success") });
      }
    } catch (error) {
      console.error("Error sending email:", error);

      if (error.response && error.response.status === 400) {
        setMessage({
          success: false,
          error: "invalid",
          text: t("email_sent_error_invalid"),
        });
      } else {
        setMessage({ success: false, text: t("email_sent_error_generic") });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <header className="px-4 lg:px-6 h-16 flex items-center sticky top-0 z-50 bg-white shadow-md">
        <Link className="flex items-center justify-center" to="/">
          <img src="/logo2.png" alt="Clubbo Logo" className="h-6 w-6" />
          <span className="ml-2 text-xl font-bold text-green-600">Clubbo</span>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex ml-auto gap-6 mr-4 justify-center items-center">
          <a className="text-sm font-medium hover:text-green-600" href="#home">
            {t("home")}
          </a>
          <a
            className="text-sm font-medium hover:text-green-600"
            href="#features"
          >
            {t("features")}
          </a>
          <a
            className="text-sm font-medium hover:text-green-600"
            href="#pricing"
          >
            {t("pricing")}
          </a>
          <a className="text-sm font-medium hover:text-green-600" href="#demo">
            {t("demo")}
          </a>
          <a
            className="text-sm font-medium hover:text-green-600"
            href="#meeting"
          >
            {t("meeting")}
          </a>
          <LanguageSwitcher />
        </nav>

        {/* Spacer for mobile to push the switcher and hamburger to the right */}
        <div className="flex-grow md:hidden"></div>

        {/* Language Switcher and Hamburger for mobile */}
        <div className="ml-auto flex items-center gap-4 md:hidden">
          <LanguageSwitcher />
          <button
            className="focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <XIcon className="h-6 w-6 text-green-600" />
            ) : (
              <MenuIcon className="h-6 w-6 text-green-600" />
            )}
            <span className="sr-only">{t("toggle_menu")}</span>
          </button>
        </div>

        {/* Overlay */}
        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ease-in-out"
            onClick={() => setIsMenuOpen(false)}
          ></div>
        )}

        {/* Mobile Navigation Menu */}
        <Transition
          show={isMenuOpen}
          enter="transition ease-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in duration-200 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <nav className="fixed top-0 left-0 h-full w-64 bg-white shadow-lg z-50">
            <div className="flex flex-col items-start py-4 px-6">
              <a
                className="text-base font-medium text-gray-800 hover:text-green-600 mb-6"
                href="#home"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("home")}
              </a>
              <a
                className="text-base font-medium text-gray-800 hover:text-green-600 mb-6"
                href="#features"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("features")}
              </a>
              <a
                className="text-base font-medium text-gray-800 hover:text-green-600 mb-6"
                href="#pricing"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("pricing")}
              </a>
              <a
                className="text-base font-medium text-gray-800 hover:text-green-600 mb-6"
                href="#contact"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("contact")}
              </a>
            </div>
          </nav>
        </Transition>
      </header>

      <main className="flex-1">
        <section
          className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-green-100"
          id="home"
        >
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
              <div className="flex flex-col justify-center space-y-4">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none text-green-800">
                  {t("header.title")}
                </h1>
                <p className="max-w-[600px] text-gray-600 md:text-xl">
                  {t("header.subtitle")}
                </p>
                <br />
                <div className="flex flex-col gap-4 items-start">
                  <p className="text-green-900 text-lg font-semibold text-center">
                    🚀 {t("header.get_notified")}
                  </p>
                  <form
                    onSubmit={handleSubmit}
                    className="relative flex items-center w-full max-w-md"
                  >
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("header.email_placeholder")}
                      className="w-full px-5 py-3 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 transition duration-300 ease-in-out md:pr-36"
                      required
                    />
                    <button
                      type="submit"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-green-600 text-white px-5 py-2 rounded-lg hover:bg-green-700 transition duration-300 ease-in-out shadow-lg flex items-center justify-center"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <ClipLoader
                          size={20}
                          color={"#ffffff"}
                          loading={isSubmitting}
                        />
                      ) : (
                        t("header.start_free")
                      )}
                    </button>
                  </form>
                  {message.text && (
                    <p
                      className={`${
                        message.success ? "text-green-700" : "text-red-700"
                      } text-sm text-center ml-2`}
                    >
                      {message.text} 📩
                    </p>
                  )}
                </div>
              </div>
              <div className="h-full w-full relative mx-auto aspect-video overflow-hidden rounded-xl bg-green-200 lg:order-last">
                <div className="h-full w-full relative">
                  <video
                    className={`object-fit object-center h-full w-full transition-opacity duration-500 ${
                      isFading ? "opacity-0" : "opacity-100"
                    }`}
                    key={currentVideo}
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source src={videos[currentVideo]} type="video/mp4" />
                    {t("video_not_supported")}
                  </video>
                </div>

                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                  {videos.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentVideo(index)}
                      className={`w-3 h-3 rounded-full ${
                        currentVideo === index ? "bg-green-600" : "bg-gray-400"
                      } hover:bg-green-100`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="w-full py-12 md:py-24 lg:py-32 bg-white"
          id="features"
        >
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 text-blue-800">
              {t("features_section.title")}
            </h2>
            <div className="grid gap-8 md:grid-cols-3">
              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg">
                <CalendarIcon className="h-12 w-12 text-green-600" />
                <h3 className="text-xl font-bold text-blue-800">
                  {t("features_section.real_time_availability.title")}
                </h3>
                <p className="text-gray-600 text-center">
                  {t("features_section.real_time_availability.description")}
                </p>
              </div>

              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg">
                <CreditCardIcon className="h-12 w-12 text-green-600" />
                <h3 className="text-xl font-bold text-blue-800">
                  {t("features_section.automated_payments.title")}
                </h3>
                <p className="text-gray-600 text-center">
                  {t("features_section.automated_payments.description")}
                </p>
              </div>

              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg">
                <ChartBarIcon className="h-12 w-12 text-green-600" />
                <h3 className="text-xl font-bold text-blue-800">
                  {t("features_section.performance_analytics.title")}
                </h3>
                <p className="text-gray-600 text-center">
                  {t("features_section.performance_analytics.description")}
                </p>
              </div>

              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg">
                <GlobeAltIcon className="h-12 w-12 text-green-600" />
                <h3 className="text-xl font-bold text-blue-800">
                  {t("features_section.public_profiles.title")}
                </h3>
                <p className="text-gray-600 text-center">
                  {t("features_section.public_profiles.description")}
                </p>
              </div>

              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg">
                <CheckCircleIcon className="h-12 w-12 text-green-600" />
                <h3 className="text-xl font-bold text-blue-800">
                  {t("features_section.booking_conflicts_resolution.title")}
                </h3>
                <p className="text-gray-600 text-center">
                  {t(
                    "features_section.booking_conflicts_resolution.description"
                  )}
                </p>
              </div>

              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg">
                <CogIcon className="h-12 w-12 text-green-600" />
                <h3 className="text-xl font-bold text-blue-800">
                  {t("features_section.flexible_customization.title")}
                </h3>
                <p className="text-gray-600 text-center">
                  {t("features_section.flexible_customization.description")}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="w-full py-12 md:py-24 lg:py-32 bg-blue-50"
          id="pricing"
        >
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 text-blue-800">
              {t("pricing_section.title")}
            </h2>
            <div className="grid gap-8 md:grid-cols-3">
              {/* Free Tier */}
              <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold text-blue-800 mb-4">
                  {t("pricing_section.free_tier.title")}
                </h3>
                <p className="text-4xl font-bold mb-6">
                  {t("pricing_section.free_tier.price")}
                </p>
                <ul className="mb-6 space-y-2">
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t("pricing_section.free_tier.features.fields_limit")}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t("pricing_section.free_tier.features.booking_fee")}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t("pricing_section.free_tier.features.profile_access")}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t("pricing_section.free_tier.features.basic_management")}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t("pricing_section.free_tier.features.email_support")}
                    </span>
                  </li>
                </ul>
                <Button className="mt-auto">
                  {t("pricing_section.free_tier.get_started")}
                </Button>
              </div>

              {/* Pro Tier */}
              <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg border-2 border-green-600 relative">
                <div className="absolute top-0 right-0 bg-green-600 text-white px-3 py-1 rounded-bl-lg text-sm font-medium">
                  {t("popular")}
                </div>
                <h3 className="text-2xl font-bold text-blue-800 mb-4">
                  {t("pricing_section.pro_tier.title")}
                </h3>
                <p className="text-4xl font-bold mb-1">
                  {t("pricing_section.pro_tier.price_main")}
                </p>
                <p className="text-base font-medium text-gray-600 mb-6">
                  {t("pricing_section.pro_tier.price_extra")}
                </p>
                <ul className="mb-6 space-y-2">
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t("pricing_section.pro_tier.features.fields_limit")}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t("pricing_section.pro_tier.features.booking_fee")}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t(
                        "pricing_section.pro_tier.features.customizable_profile"
                      )}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t(
                        "pricing_section.pro_tier.features.advanced_management"
                      )}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t("pricing_section.pro_tier.features.priority_support")}
                    </span>
                  </li>
                </ul>
                <Button className="mt-auto">
                  {t("pricing_section.pro_tier.choose_pro")}
                </Button>
              </div>

              {/* Enterprise Tier */}
              <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold text-blue-800 mb-4">
                  {t("pricing_section.enterprise_tier.title")}
                </h3>
                <p className="text-4xl font-bold mb-6">
                  {t("pricing_section.enterprise_tier.price")}
                </p>
                <ul className="mb-6 space-y-2">
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t(
                        "pricing_section.enterprise_tier.features.fields_unlimited"
                      )}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t(
                        "pricing_section.enterprise_tier.features.custom_management"
                      )}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t(
                        "pricing_section.enterprise_tier.features.priority_support"
                      )}
                    </span>
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="h-5 w-5 text-green-600 mr-2" />
                    <span>
                      {t(
                        "pricing_section.enterprise_tier.features.advanced_analytics"
                      )}
                    </span>
                  </li>
                </ul>
                <Button variant="outline" className="mt-auto">
                  {t("pricing_section.enterprise_tier.contact_sales")}
                </Button>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="w-full py-12 md:py-24 lg:py-32 bg-blue-50">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 text-blue-800">
              {t("testimonials_section.title")}
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg bg-white">
                <p className="text-gray-600 text-center italic">
                  "{t("testimonials_section.testimonial_1.quote")}"
                </p>
                <p className="text-sm font-semibold">
                  - {t("testimonials_section.testimonial_1.author")}
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg bg-white">
                <p className="text-gray-600 text-center italic">
                  "{t("testimonials_section.testimonial_2.quote")}"
                </p>
                <p className="text-sm font-semibold">
                  - {t("testimonials_section.testimonial_2.author")}
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border border-gray-200 p-6 rounded-lg bg-white">
                <p className="text-gray-600 text-center italic">
                  "{t("testimonials_section.testimonial_3.quote")}"
                </p>
                <p className="text-sm font-semibold">
                  - {t("testimonials_section.testimonial_3.author")}
                </p>
              </div>
            </div>
          </div>
        </section> */}
        <section
          id="demo"
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-blue-50 to-white"
        >
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 text-blue-800">
              {t("demo_section.title")}
            </h2>
            <div className="max-w-5xl mx-auto">
              {loading ? (
                <div className="flex justify-center items-center h-[30vh] bg-gray-100 rounded-lg">
                  <div className="animate-spin rounded-full mr-4 h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
                  <p>{t("demo_section.loading.message")}</p>
                </div>
              ) : videoAvailable ? (
                <div className="aspect-w-16 aspect-h-9 h-[60vh]">
                  <iframe
                    className="w-full h-full rounded-lg shadow-lg"
                    src={t("demo_section.videoAvailable.iframeSrc")}
                    title={t("demo_section.videoAvailable.iframeTitle")}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <div className="bg-blue-100 p-8 rounded-lg shadow-lg text-center flex flex-col justify-center">
                  <h3 className="text-2xl font-semibold text-blue-800 mb-4">
                    {t("demo_section.comingSoon.heading")}
                  </h3>
                  <p className="text-lg text-gray-800 mb-6">
                    {t("demo_section.comingSoon.description")}
                  </p>
                  <div className="space-y-4">
                    <p className="text-sm text-blue-800">
                      {t("demo_section.comingSoon.notifyMessage")}
                    </p>
                    <form
                      onSubmit={handleSubmit}
                      className="flex flex-col sm:flex-row gap-2 justify-center"
                    >
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t(
                          "demo_section.comingSoon.inputPlaceholder"
                        )}
                        className="px-4 py-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                      />
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        {isSubmitting ? (
                          <ClipLoader
                            size={20}
                            color={"#ffffff"}
                            loading={isSubmitting}
                          />
                        ) : (
                          t("demo_section.comingSoon.notifyButton")
                        )}
                      </button>
                    </form>
                    {message.text && (
                      <p
                        className={`${
                          message.success ? "text-green-700" : "text-red-700"
                        } text-sm text-center mt-3`}
                      >
                        {message.text} 📩
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section
          id="meeting"
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-white to-blue-50"
        >
          <div className="container mx-auto px-4 md:px-6">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold tracking-tight text-blue-800 sm:text-4xl mb-4">
                {t("consultation_section.title")}
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-600 mb-8">
                {t("consultation_section.description")}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
                  <Calendar className="w-8 h-8 text-blue-600 mb-2" />
                  <h3 className="text-lg font-semibold text-gray-900">
                    {t(
                      "consultation_section.features.flexibleScheduling.title"
                    )}
                  </h3>
                  <p className="text-sm text-gray-600 text-center">
                    {t(
                      "consultation_section.features.flexibleScheduling.description"
                    )}
                  </p>
                </div>
                <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
                  <Clock className="w-8 h-8 text-blue-600 mb-2" />
                  <h3 className="text-lg font-semibold text-gray-900">
                    {t("consultation_section.features.sessionLength.title")}
                  </h3>
                  <p className="text-sm text-gray-600 text-center">
                    {t(
                      "consultation_section.features.sessionLength.description"
                    )}
                  </p>
                </div>
                <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
                  <Video className="w-8 h-8 text-blue-600 mb-2" />
                  <h3 className="text-lg font-semibold text-gray-900">
                    {t("consultation_section.features.videoConsultation.title")}
                  </h3>
                  <p className="text-sm text-gray-600 text-center">
                    {t(
                      "consultation_section.features.videoConsultation.description"
                    )}
                  </p>
                </div>
              </div>
              <a
                href="https://calendly.com/maury-clubbo/30min"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:py-4 md:text-lg md:px-10 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
              >
                {t("consultation_section.button")}
              </a>
            </div>
          </div>
        </section>
        {/* <section
          id="contact"
          className="w-full py-12 md:py-24 lg:py-32 bg-white"
        >
          <div className="container mx-auto px-4 md:px-6 text-center">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl mb-4 text-blue-800">
              {t("contact_section.title")}
            </h2>
            <p className="max-w-[600px] mx-auto text-gray-600 md:text-xl mb-8">
              {t("contact_section.subtitle")}
            </p>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto relative">
              <div className="flex flex-col gap-2">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("header.email_placeholder")}
                  className="w-full px-5 py-3 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 transition duration-300 ease-in-out"
                  required
                  onFocus={(e) =>
                    e.target.scrollIntoView({ behavior: "smooth" })
                  } // Asegura que el campo de texto se vea
                />
                <button
                  type="submit"
                  className="bg-green-600 text-white px-5 py-2 rounded-lg hover:bg-green-700 transition duration-300 ease-in-out shadow-lg flex items-center justify-center"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <ClipLoader
                      size={20}
                      color={"#ffffff"}
                      loading={isSubmitting}
                    />
                  ) : (
                    t("contact_section.sign_up")
                  )}
                </button>
              </div>
            </form>

            {message.text && (
              <p
                className={`${
                  message.success ? "text-green-700" : "text-red-700"
                } text-sm text-center mt-3`}
              >
                {message.text} 📩
              </p>
            )}
          </div>
        </section> */}
      </main>
      <footer className="py-6 w-full px-4 md:px-6 border-t bg-gray-100">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm text-gray-600">{t("footer.copyright")}</p>
          <nav className="flex gap-4 mt-4 md:mt-0">
            <Link
              className="text-sm text-gray-600 hover:text-gray-900"
              href="#"
            >
              {t("footer.privacy_policy")}
            </Link>
            <Link
              className="text-sm text-gray-600 hover:text-gray-900"
              href="#"
            >
              {t("footer.terms_of_service")}
            </Link>
          </nav>
          <div className="flex gap-4 mt-4 md:mt-0">
            <a
              href="https://x.com/clubboapp"
              className="text-gray-600 hover:text-gray-900"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="h-5 w-5" />
            </a>
            <a
              href="https://www.instagram.com/clubbo.io/"
              className="text-gray-600 hover:text-gray-900"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="h-5 w-5" />
            </a>
            <a
              href="https://www.linkedin.com/company/clubbo-io"
              className="text-gray-600 hover:text-gray-900"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="h-5 w-5" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}

function CalendarIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
      <line x1="16" x2="16" y1="2" y2="6" />
      <line x1="8" x2="8" y1="2" y2="6" />
      <line x1="3" x2="21" y1="10" y2="10" />
    </svg>
  );
}

function CreditCardIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="14" x="2" y="5" rx="2" />
      <line x1="2" x2="22" y1="10" y2="10" />
    </svg>
  );
}
