import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translation_es from "./locales/es/translation.json";
import translation_en from "./locales/en/translation.json";

i18n
  .use(LanguageDetector) // Habilita la detección de idioma
  .use(initReactI18next) // Pasa la instancia de i18next a react-i18next.
  .init({
    resources: {
      en: {
        translation: translation_en,
      },
      es: {
        translation: translation_es,
      },
    },
    lng: navigator.language.startsWith("es") ? "es" : "en", // establece el idioma según el navegador
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
